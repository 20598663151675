import React from "react"

import Layout from "../components/global/layout"
import ServicesHeader from '../components/services/ServicesHeader'
import ServicesContent from '../components/services/ServicesContent'

import GetInTouchToday from '../components/global/GetInTouchToday';
import SEO from "../components/global/seo"
import {graphql, StaticQuery} from "gatsby";


export default () => (
    <StaticQuery
        query={graphql`
      query ServicesPageQuery {
        allStrapiServicespage {
          edges {
            node {
              MetaDescription
            }
          }
        }
      }
    `}
        render={data => (
            <Layout>
                {data.allStrapiServicespage.edges.map(document => (
                    <SEO
                        title="Services" keywords={[`Digital Services`, `IT Services`, `UX Design`]}
                        description={document.node.MetaDescription}
                    />
                ))}
                <ServicesHeader />
                <ServicesContent />
                <GetInTouchToday />
            </Layout>
        )}
    />
);