import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./ServicesHeader.css";
import ServicesImage from "../../assets/services/valor-digital-services-sydney.jpg";
import GenericHeader from "../templates/genericHeader";

export default () => (
    <StaticQuery
        query={graphql`
      query ServicesHeadingQuery {
        allStrapiServicespage {
          edges {
            node {
              PageHeading,
              PageHeadingBlurb
            }
          }
        }
      }
    `}
        render={data => (
            <div className="tabarja-about">
                <GenericHeader
                    Heading={data.allStrapiServicespage.edges.map(document => (
                        <div>{document.node.PageHeading}</div>
                    ))}
                    headerInfo={data.allStrapiServicespage.edges.map(document => (
                        <div>{document.node.PageHeadingBlurb}</div>
                    ))}
                    infoHeaderImage={ServicesImage}
                    infoHeaderImageAlt={"Digital Services Tabarja Digital Solutions"}
                />
            </div>
        )}
    />
);