import React from 'react';
import { Col } from 'reactstrap';

export default class ServicesWidget extends React.Component {
    viewMore(){
        var widgetTitle = this.refs.widgetTitle.value;
        if(widgetTitle == "CRM"){
            console.log("CRM()");
        }
        if(widgetTitle == "Web Solutions"){
            console.log("Web Solutions()");
        }
        if(widgetTitle == "Apps"){
            console.log("Apps()");
        }
        if(widgetTitle == "Infrastructure"){
            console.log("Infrastructure()");
        }
    }

    render(){
        return(
            <Col md="5" className="outline-border p-4 ml-md-3 mb-5">
                <div className="d-flex align-items-center">
                    <input type="hidden" value={this.props.ServiceTitle} ref="widgetTitle" />
                    <img className="mw-100" src={this.props.ServiceHeaderImg} alt="Valor Digital Solutions"/>
                </div>
                <h4 className="mt-5 mb-4">{this.props.ServiceTitle}</h4>
                <div className="mb-lg-5">
                    <p>{this.props.Serviceinfo}</p>
                </div>
                <div className="link-more">
                    <a onClick={this.viewMore.bind(this)} className="link-more text-dark btn-more" >View more</a>
                </div>
            </Col>
        )
    }
}