import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import './ServicesContent.css';
import ServicesWidget from './ServicesWidget';
import { Row, Col } from 'reactstrap';
import BoxWidget from '../global/BoxWidget';

import WidgetOneImage from '../../assets/services/digital-marketing-sydney-valor.png'
import WidgetTwoImage from '../../assets/services/consulting-sydney-valor.png'
import WidgetThreeImage from '../../assets/services/user-experience-ux-design-sydney-tile.jpg'
import WidgetFourImage from '../../assets/services/software-development-sydney-valor.jpg'

export default () => (
    <StaticQuery
        query={graphql`
        query ServiceContentQuery {
          allStrapiServicespage {
            edges {
              node {
                ContentHeading
                ContentBody
                WidgetOneTitle
                WidgetOneInfo
                WidgetTwoTitle
                WidgetTwoInfo
                WidgetThreeTitle
                WidgetThreeInfo
                WidgetFourTitle
                WidgetFourInfo
              }
            }
          }
        }
      `}
        render={data => (
          
            <div>
                <section>
                    <div className="container-fluid">
                        <Row>
                            <Col lg="8" className="pr-lg-0 sb-left">
                                <div className="d-flex pt-5 pb-5">
                                    <div className="pl">
                                        {data.allStrapiServicespage.edges.map(document => (
                                            <h3 className="sec-header-sub">
                                            {document.node.ContentHeading}
                                            </h3>
                                        ))}   
                                        
                                            
                                        {data.allStrapiServicespage.edges.map(document => (
                                            <p className="sec-para">
                                            {document.node.ContentBody}
                                            </p>
                                        ))}  
                                    </div>
                                </div>
                                <div className="row pl pb-lg-5 justify-content-between s-widget-holder">
                                {data.allStrapiServicespage.edges.map(document => (
                                    
                                    <ServicesWidget
                                        ServiceTitle={document.node.WidgetOneTitle}
                                        ServiceHeaderImg={WidgetOneImage}
                                        Serviceinfo={document.node.WidgetOneInfo}
                                    />
                                ))}
                                    
                                {data.allStrapiServicespage.edges.map(document => (
                                    
                                    <ServicesWidget
                                        ServiceTitle={document.node.WidgetTwoTitle}
                                        ServiceHeaderImg={WidgetTwoImage}
                                        Serviceinfo={document.node.WidgetTwoInfo}
                                    />
                                ))}
                                    
                                    {data.allStrapiServicespage.edges.map(document => (
                                    
                                    <ServicesWidget
                                        ServiceTitle={document.node.WidgetThreeTitle}
                                        ServiceHeaderImg={WidgetThreeImage}
                                        Serviceinfo={document.node.WidgetThreeInfo}
                                    />
                                    ))}
                                    
                                    {data.allStrapiServicespage.edges.map(document => (
                                    
                                    <ServicesWidget
                                        ServiceTitle={document.node.WidgetFourTitle}
                                        ServiceHeaderImg={WidgetFourImage}
                                        Serviceinfo={document.node.WidgetFourInfo}
                                    />
                                    ))}
                                    
                                </div>
                                
                            </Col>
                            <Col lg="4" className="p-lg-5 sb-right mb-5">
                                <BoxWidget />
                                {/* <div className="chat-widget box-type-1 mt-5 d-flex">
                                    <div className="p-4 ">
                                        <h4>Our Services</h4>
                                        <ul className="list-unstyled">
                                            <li><a >Digital Marketing</a></li>
                                            <li><a >Consulting</a></li>
                                            <li><a >UX Services</a></li>
                                            <li><a >Software Development</a></li>
                                        </ul>
                                    </div>
                                </div> */}                               
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
         )}
         />
       );
    
